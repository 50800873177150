<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant
            type="search"
            name="plantCd"
            v-model="searchParam.plantCd">
          </c-plant>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd='RAM_RISK_HAZARD_CLASS_CD'
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramRiskHazardClassCd"
            label="LBLCLASSIFICATION"
            v-model="searchParam.ramRiskHazardClassCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험물질 목록"
      :merge="grid.merge"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :selection="popupParam.type"
      rowKey="mdmChemId"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazardChemPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
      },
      grid: {
        merge: [
          { index: 0, colName: 'materialName' },
        ],
        columns: [
          {
            name: 'materialName',
            field: 'materialName',
            label: '화학자재',
            align: 'left',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'chemNmEn',
            field: 'chemNmEn',
            label: '물질명',
            align: 'left',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'casNo',
            field: 'casNo',
            label: 'CAS No.',
            align: 'center',
            style: 'width:90px',
            sortable: true,
          },
          {
            name: 'limitRepval',
            field: 'limitRepval',
            label: '함량 (%)',
            align: 'right',
            style: 'width:70px',
            sortable: true,
          },
          {
            name: 'propertiesStateName',
            field: 'propertiesStateName',
            label: '저장성상<br/>(액체/고상/기상)',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        height: '500px',
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    contentHeight() {
      let height = (this.contentHeight - this.$refs['searchbox'].$el.offsetHeight - 50);
      if (height < 400) {
        height = 500;
      }
      this.grid.height = height + 'px'
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.mam.chem.hazardChem.url;
      // code setting
      if (this.popupParam.plantCd) {
        this.$set(this.searchParam, 'plantCd', this.popupParam.plantCd)
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '선택된 정보가 없습니다.', // 선택된 정보가 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
